.sidebar-toogle-btn {
  display: none;
}

@media (min-width: 768px) {
	.custom-sidebar-navbar-style {
		display: none !important;
	}
	.custom-sidebar-item {
		padding-top: 80px !important;
		.nav-link {
			font-size: .9rem !important;
			font-weight: 600 !important;
		}
	}
	.custom-margin-top {
		padding-top: 100px !important;
		padding-bottom: 50px !important;
	}
	.custom-width {
		max-width: 4rem !important;
	}
	.custom-main-content {
		margin-left: 4rem !important;
	}
	.custom-navbar.container-fluid {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
	.extra-sidebar-style {
		box-shadow: 0 0.8rem 1.2rem 0 rgba(206, 212, 218, 40%) !important;
		overflow-y: visible !important;
		overflow-x: visible;
    z-index: 9;
	}
	.custom-d-none {
		display: none !important;
	}
	.custom-btn-style.custom-float-btn {
		float: right !important;
	}
	.custom-form-control-sm {
		height: calc(1.5em + 0.5rem + 2px);
		padding: 0.25rem 0.5rem;
		font-size: 0.75rem;
		line-height: 1.5;
		border-radius: 0.25rem;
	}

  .sidebar-toogle-btn {
    display: block;
    position: absolute;
    top: 26px;
    width: 24px;
    right: -12px;
    left: auto;
    z-index: 99;
    background: linear-gradient(
      to right,
      #11CBEF 0%,
      #11CBEF 50%,
      #FFFFFF 50%,
      #FFFFFF 100%
    );
    align-items: center;
    color: $dark;
    cursor: pointer;
  }

  .not-is-open .container-fluid .navbar-brand {
    margin-top: 0.3rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}

.custom-nav-link {
  pointer-events:none !important;
  &:after {
    pointer-events: initial !important;
  }

  .custom--link {
    color: rgba(0, 0, 0, 0.5);
    pointer-events: initial !important;
  }

  &.active {
    .custom--link {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  &:hover {
    .custom--link {
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.breadcrumb {
  background-color: transparent !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}



/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8898AA;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8898AA;
}
