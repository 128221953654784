.navbar .collapsing .navbar-toggler{
  pointer-events: none;
}
.navbar .nav-item .nav-link-icon.nav-link i{
  margin-right: 4px;
}

.profile-rounded-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.25rem;
  height: 2.5rem;
  width: 2.5rem;
  background-color: rgba($color: white, $alpha: 0.4);
  border: 1px solid #c7cbd0;
}
